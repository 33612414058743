<template>
    <div class="mb-30 mb-md-70">
        <v-btn-toggle borderless class="w-100 shadow-primary hidden">
            <v-btn v-for="{ to, text } in items" :key="to" :to="to" color="white" :class="{ 'primary': $route.path.includes(to) }" class="v-size--xx-large">
                <span :class="{ 'white--text': $route.path.includes(to) }">{{ text }}</span>
            </v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
export default {
    props: {
        items: { type: Array, default: () => [{ to: "/", text: "Home" }] },
    },
};
</script>

<style scoped>
.v-btn-toggle{
    flex-wrap: wrap;
    border-radius: 10px;
}
.v-btn-toggle .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before{
    opacity: 0;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--xx-large{
    flex: 1 1 0;
    min-width:auto;
    height: 50px;
    font-size: 1.6rem;
    border-radius: 0 !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-btn-toggle{
        border-radius: 20px;
    }
    .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--xx-large{
        height: 72px;
        font-size: 2rem;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>