var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "visual": "고객센터",
      "subTitle": "CUSTOMER CENTER"
    }
  }), _c('v-container', [_c('center-navigation-row'), !_vm.$route.params._notification ? _c('notification-list') : _c('notification-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }