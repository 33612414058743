var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-visual"
  }, [_c('div', {
    staticClass: "sub-visual__wrapper"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--subtitle text-center"
  }, [_c('small', [_vm._v(_vm._s(_vm.subTitle))]), _c('h2', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.visual))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }