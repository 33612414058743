<template>
	<client-page>
        <sub-visual visual="고객센터" subTitle="CUSTOMER CENTER" />

        <v-container>
            <center-navigation-row />

            <!-- 1. 게시판 리스트: 베이직 스킨 -->
            <notification-list v-if="!$route.params._notification"></notification-list>
            <notification-view v-else></notification-view>
            <!-- 2. 게시판 작성: 베이직 스킨 -->
            <!-- <board-basic-input></board-basic-input> -->
            <!-- 3. 게시판 뷰: 베이직 스킨 -->
            <!-- <board-basic-view></board-basic-view> -->
        </v-container>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import CenterNavigationRow from "@/components/client/center-navigation-row.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default{
	components: {
        ClientPage,
        SubVisual,
        CenterNavigationRow,
		NotificationList,
		NotificationView
	},
}
</script>
