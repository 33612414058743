var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-list"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt d-none d-lg-table-cell",
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.number")))]), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "70%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.제목")))]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "16%"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.작성일")))])])]), _c('tbody', [_vm._l(_vm.fixed, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")])], 1), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(notification.subject))])])]), _c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")])]);
  }), _vm._l(_vm.notifications, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('span', [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))])]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(notification.subject))])])]), _c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")])]);
  })], 2)]), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }