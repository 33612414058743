<template>
	<client-page>
        <sub-visual visual="고객센터" subTitle="CUSTOMER CENTER" />

        <v-container>
            <center-navigation-row />

           <faq-list></faq-list>
        </v-container>
	</client-page>
</template>

<script>

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import CenterNavigationRow from "@/components/client/center-navigation-row.vue";
import FaqList from "@/components/client/center/faqs/faq-list.vue";

export default{
	components: {
        ClientPage,
        SubVisual,
        CenterNavigationRow,
		FaqList,
    },
}
</script>
