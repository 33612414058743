<template>
    <div class="board-container">
        <div class="board-list-faq">
            <v-expansion-panels accordion>
                <v-expansion-panel v-for="(faq, index) in fixed" :key="index">
                    <v-expansion-panel-header>
                        <div class="pa-10 pa-md-20">
                            <div class="d-md-flex flex-wrap align-center">
                                <div class="mb-10 mb-md-0 mr-10 mr-md-20">
                                    <v-icon size="28">mdi-alert-circle-outline</v-icon>
                                </div>
                                <div v-if="faq.type" class="mb-10 mb-md-0 mr-10 mr-md-20">
                                    <v-chip small outlined color="grey-d" class="w-100px justify-center cursor-pointer px-10"
                                        ><span class="grey-7--text">{{ $t(`faq.type['${faq.type}']`) }}</span></v-chip
                                    >
                                </div>
                                <div class="d-flex flex-wrap align-center">
                                    <v-avatar size="28" color="grey-2" class="font-size-16 font-weight-bold line-height-1 white--text mr-10">Q</v-avatar>
                                    <strong class="font-size-14 font-size-md-16 grey-2--text">{{ faq.subject }}</strong>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="board-list-faq__header">
                        <div class="board-list-faq__header-mark">
                            <span>Q</span>
                        </div>
                        <div class="board-list-faq__tit">
                            <span class="board-list-faq__category">{{ $t(`faq.type['${faq.type}']`) }}</span>
                            <span>{{ faq.subject }}</span>
                        </div>
                    </div> -->
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-divider />
                        <div class="grey-f7 px-10 py-14 pa-md-20">
                            <div class="d-md-flex flex-wrap align-center">
                                <div v-if="faq.type" class="w-100px mr-10 mr-md-20"></div>
                                <div class="d-flex flex-wrap">
                                    <v-avatar size="28" color="#9197a9" class="font-size-16 font-weight-bold line-height-1 white--text mr-10">A</v-avatar>
                                    <span v-html="faq.content.replace(/\n/g, '<br>')" class="font-size-14 font-size-md-16 grey-7--text mt-2"></span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="board-list-faq__content">
                        <div class="board-list-faq__content-mark"><span>A</span></div>
                        <div class="board-list-faq__txt">
                            <span v-html="faq.content.replace(/\n/g, '<br>')" class="board-list-faq__html"></span>
                        </div>
                    </div> -->
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
                    <v-expansion-panel-header>
                        <div class="pa-10 pa-md-20">
                            <div class="d-md-flex flex-wrap align-center">
                                <div v-if="faq.type" class="mb-10 mb-md-0">
                                    <v-chip small outlined color="grey-d" class="w-100px justify-center cursor-pointer px-10 mr-10 mr-md-20"
                                        ><span class="grey-7--text">{{ $t(`faq.type['${faq.type}']`) }}</span></v-chip
                                    >
                                </div>
                                <div class="d-flex flex-wrap align-center">
                                    <v-avatar size="28" color="grey-2" class="font-size-16 font-weight-bold line-height-1 white--text mr-10">Q</v-avatar>
                                    <strong class="font-size-14 font-size-md-16 grey-2--text">{{ faq.subject }}</strong>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="board-list-faq__header">
                        <div class="board-list-faq__header-mark">
                            <span>Q</span>
                        </div>
                        <div class="board-list-faq__tit">
                            <span class="board-list-faq__category">{{ $t(`faq.type['${faq.type}']`) }}</span>
                            <span>{{ faq.subject }}</span>
                        </div>
                    </div> -->
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-divider />
                        <div class="grey-f7 px-10 py-14 pa-md-20">
                            <div class="d-md-flex flex-wrap align-center">
                                <div v-if="faq.type" class="w-100px mr-10 mr-md-20"></div>
                                <div class="d-flex flex-wrap">
                                    <v-avatar size="28" color="#9197a9" class="font-size-16 font-weight-bold line-height-1 white--text mr-10">A</v-avatar>
                                    <span v-html="faq.content.replace(/\n/g, '<br>')" class="font-size-14 font-size-md-16 grey-7--text mt-2"></span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="board-list-faq__content">
                        <div class="board-list-faq__content-mark"><span>A</span></div>
                        <div class="board-list-faq__txt">
                            <span v-html="faq.content.replace(/\n/g, '<br>')" class="board-list-faq__html"></span>
                        </div>
                    </div> -->
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            filter: {
                type: null,
            },
            faqs: [],
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.query.keyword"() {
            this.search({ withoutRouting: true });
        },
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search() {
            try {
                var { faqs: fixed } = await api.v1.center.faqs.gets({
                    headers: { limit: 0 },
                    params: { ...this.filter, isNotice: true, searchValue: this.$route.query.keyword },
                });
                this.fixed = fixed;

                var { summary, faqs } = await api.v1.center.faqs.gets({
                    params: { ...this.filter, isNotice: false, searchValue: this.$route.query.keyword },
                });

                this.faqs = faqs;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style scoped>
/* Board Style - FAQ */
.board-list-faq {
    border-top: 2px solid #222;
    border-bottom: 1px solid var(--border-color);
}
.board-list-faq .v-expansion-panel:before {
    box-shadow: none !important;
}
.board-list-faq .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after {
    border-color: var(--border-color);
}
.board-list-faq .v-expansion-panel-header {
    min-height: 54px;
    padding: 0 10px 0 0;
}
.board-list-faq__header {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6rem;
}
.board-list-faq__header-mark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--v-primary-base);
}
.board-list-faq__tit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 54px);
    font-size: 1.6rem;
    font-weight: 700;
    color: #222;
}
.board-list-faq .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: #686868;
}
>>> .board-list-faq .v-expansion-panel-content__wrap {
    padding: 0;
}
.board-list-faq__content {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 24px 24px 54px;
    background: #efefef;
}
.board-list-faq__content-mark {
    width: 54px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    color: #686868;
    margin-left: -54px;
}
.board-list-faq__txt {
    width: 100%;
}
.board-list-faq__txt,
.board-list-faq__txt > * {
    font-size: 1.4rem;
    color: #686868;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .board-list-faq .v-expansion-panel-header {
        padding: 0 20px 0 0;
    }
}
@media (min-width: 1024px) {
    .board-list-faq .v-expansion-panel-header {
        min-height: 74px;
        padding: 0 20px 0 0;
    }
    .board-list-faq__header-mark {
        width: 64px;
        height: 64px;
        font-size: 1.8rem;
    }
    .board-list-faq__tit {
        width: calc(100% - 64px);
        font-size: 1.8rem;
    }
    .board-list-faq__content {
        padding: 24px 24px 24px 64px;
    }
    .board-list-faq__content-mark {
        font-size: 1.8rem;
        width: 64px;
        margin-left: -64px;
        /* width: 20px;
        font-size: 1.6rem;
        text-align: left;
        margin-left: 0; */
    }
    .board-list-faq__txt {
        width: calc(100% - 20px);
    }
    .board-list-faq__txt,
    .board-list-faq__txt > * {
        font-size: 1.6rem;
    }
}
@media (min-width: 1200px) {
}
</style>
