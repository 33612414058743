<template>
    <div class="sub-visual">
        <div class="sub-visual__wrapper">
            <div class="tit-wrap tit-wrap--subtitle text-center">
                <small>{{subTitle}}</small>
                <h2 class="tit">{{visual}}</h2>
            </div>
        </div>
	</div>
</template>

<script>

export default{
    props : {
        subTitle : {type :String, default : ""},
        visual : {type :String, default : ""}
    },
	components: {

	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.sub-visual{
    padding: 60px 0 0;
    margin-top:-60px;
}
.sub-visual__wrapper{
    width: 100%;
    max-width: calc(var(--container) + 24px);
    margin: auto;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .sub-visual{
        padding: 80px 0 0;
        margin-top:-80px;
    }
}
@media (min-width:1024px){
    .sub-visual{
        padding: 120px 0 0;
        margin-top:-120px;
    }
}
@media (min-width:1200px){
}
</style>