var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-30 mb-md-70"
  }, [_c('v-btn-toggle', {
    staticClass: "w-100 shadow-primary hidden",
    attrs: {
      "borderless": ""
    }
  }, _vm._l(_vm.items, function (_ref) {
    var to = _ref.to,
      text = _ref.text;
    return _c('v-btn', {
      key: to,
      staticClass: "v-size--xx-large",
      class: {
        'primary': _vm.$route.path.includes(to)
      },
      attrs: {
        "to": to,
        "color": "white"
      }
    }, [_c('span', {
      class: {
        'white--text': _vm.$route.path.includes(to)
      }
    }, [_vm._v(_vm._s(text))])]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }