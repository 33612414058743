var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-list-faq"
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, [_vm._l(_vm.fixed, function (faq, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_c('div', {
      staticClass: "pa-10 pa-md-20"
    }, [_c('div', {
      staticClass: "d-md-flex flex-wrap align-center"
    }, [_c('div', {
      staticClass: "mb-10 mb-md-0 mr-10 mr-md-20"
    }, [_c('v-icon', {
      attrs: {
        "size": "28"
      }
    }, [_vm._v("mdi-alert-circle-outline")])], 1), faq.type ? _c('div', {
      staticClass: "mb-10 mb-md-0 mr-10 mr-md-20"
    }, [_c('v-chip', {
      staticClass: "w-100px justify-center cursor-pointer px-10",
      attrs: {
        "small": "",
        "outlined": "",
        "color": "grey-d"
      }
    }, [_c('span', {
      staticClass: "grey-7--text"
    }, [_vm._v(_vm._s(_vm.$t(`faq.type['${faq.type}']`)))])])], 1) : _vm._e(), _c('div', {
      staticClass: "d-flex flex-wrap align-center"
    }, [_c('v-avatar', {
      staticClass: "font-size-16 font-weight-bold line-height-1 white--text mr-10",
      attrs: {
        "size": "28",
        "color": "grey-2"
      }
    }, [_vm._v("Q")]), _c('strong', {
      staticClass: "font-size-14 font-size-md-16 grey-2--text"
    }, [_vm._v(_vm._s(faq.subject))])], 1)])])]), _c('v-expansion-panel-content', [_c('v-divider'), _c('div', {
      staticClass: "grey-f7 px-10 py-14 pa-md-20"
    }, [_c('div', {
      staticClass: "d-md-flex flex-wrap align-center"
    }, [faq.type ? _c('div', {
      staticClass: "w-100px mr-10 mr-md-20"
    }) : _vm._e(), _c('div', {
      staticClass: "d-flex flex-wrap"
    }, [_c('v-avatar', {
      staticClass: "font-size-16 font-weight-bold line-height-1 white--text mr-10",
      attrs: {
        "size": "28",
        "color": "#9197a9"
      }
    }, [_vm._v("A")]), _c('span', {
      staticClass: "font-size-14 font-size-md-16 grey-7--text mt-2",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })], 1)])])], 1)], 1);
  }), _vm._l(_vm.faqs, function (faq, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_c('div', {
      staticClass: "pa-10 pa-md-20"
    }, [_c('div', {
      staticClass: "d-md-flex flex-wrap align-center"
    }, [faq.type ? _c('div', {
      staticClass: "mb-10 mb-md-0"
    }, [_c('v-chip', {
      staticClass: "w-100px justify-center cursor-pointer px-10 mr-10 mr-md-20",
      attrs: {
        "small": "",
        "outlined": "",
        "color": "grey-d"
      }
    }, [_c('span', {
      staticClass: "grey-7--text"
    }, [_vm._v(_vm._s(_vm.$t(`faq.type['${faq.type}']`)))])])], 1) : _vm._e(), _c('div', {
      staticClass: "d-flex flex-wrap align-center"
    }, [_c('v-avatar', {
      staticClass: "font-size-16 font-weight-bold line-height-1 white--text mr-10",
      attrs: {
        "size": "28",
        "color": "grey-2"
      }
    }, [_vm._v("Q")]), _c('strong', {
      staticClass: "font-size-14 font-size-md-16 grey-2--text"
    }, [_vm._v(_vm._s(faq.subject))])], 1)])])]), _c('v-expansion-panel-content', [_c('v-divider'), _c('div', {
      staticClass: "grey-f7 px-10 py-14 pa-md-20"
    }, [_c('div', {
      staticClass: "d-md-flex flex-wrap align-center"
    }, [faq.type ? _c('div', {
      staticClass: "w-100px mr-10 mr-md-20"
    }) : _vm._e(), _c('div', {
      staticClass: "d-flex flex-wrap"
    }, [_c('v-avatar', {
      staticClass: "font-size-16 font-weight-bold line-height-1 white--text mr-10",
      attrs: {
        "size": "28",
        "color": "#9197a9"
      }
    }, [_vm._v("A")]), _c('span', {
      staticClass: "font-size-14 font-size-md-16 grey-7--text mt-2",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })], 1)])])], 1)], 1);
  })], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }