<template>
    <navigation-row v-bind="{ items }" />
</template>

<script>
import NavigationRow from "../dumb/navigation-row.vue";
const items = [
    { to: "/center/notice", text: "공지사항" },
    { to: "/center/faq", text: "FAQ" },
];
export default {
    components: { NavigationRow },
    data: () => ({
        items,
    }),
};
</script>

<style></style>
